// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { FolderPath } from "./folderPath";

export class Repository {
    packageManager: string;
    projectId: string;
    repositoryId: string;
    url: string;
    name: string;
    folderPaths: FolderPath[];
    fixedIssuesCount: number;
    openIssuesCount: number;
    totalIssuesCount?: number;
    totalDependencyCount?: number;

    constructor(json: any) {
        this.packageManager = json.packageManager;
        this.projectId = json.projectId;
        this.repositoryId = json.repositoryId;
        this.url = json.url;
        this.name = json.name;
        this.folderPaths = json.folderPaths;
        this.fixedIssuesCount = json.fixedIssuesCount;
        this.openIssuesCount = json.openIssuesCount;
        this.totalIssuesCount = json.totalIssuesCount;
        this.totalDependencyCount = json.totalDependencyCount;
    }
}
