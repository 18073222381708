// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';
import { PrismModule } from '@ngx-prism/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DetailsComponent } from './pages/details/details.component';
import { NotValidPageComponent } from './pages/not-valid-page/not-valid-page.component';
import { Globals } from './globals';
import { SharedModule } from './shared/shared.module';
import { DiscoverComponent } from './pages/discover/discover.component';
import { AuthService } from './services/auth.service';
import { LandingComponent } from './pages/landing/landing.component';
import { RedirectingModule } from './redirecting/redirecting.module';
import { MyProjectsComponent } from './pages/my-projects/my-projects.component';
import { ApproveComponent } from './pages/approve/approve.component';
import { IconLoaderComponent } from './shared/icon-loader/icon-loader.component';
import { SettingComponent } from './pages/setting/setting.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ButtonsModule } from 'ngx-bootstrap/buttons';


export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();
    const linkRenderer = renderer.link;

    renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" ');
    };

    return {
        renderer: renderer,
    };
}

@NgModule({
    declarations: [
        AppComponent,
        DetailsComponent,
        NotValidPageComponent,
        DiscoverComponent,
        LandingComponent,
        MyProjectsComponent,
        ApproveComponent,
        IconLoaderComponent,
        SettingComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useFactory: markedOptionsFactory,
            },
        }),
        HttpClientModule,
        FormsModule,
        PrismModule,
        SharedModule,
        InfiniteScrollModule,
        RedirectingModule,
        ButtonsModule.forRoot(),
    ],
    providers: [Globals, AuthService],
    bootstrap: [AppComponent],
})
export class AppModule { }
