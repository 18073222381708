// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';
import { AppConstants } from 'src/config/constants/app-constants';
import { ToastrService } from 'ngx-toastr';

export interface GithubCallbackResult {
  redirectPath: any[];
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  protocol = '//';
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  loginUser(): Observable<any> {

    const httpOptions = {
      headers: this.authService.getRequestHeaders()
    };
    return this.http.post(this.protocol + environment.vdAPIEndpointHostname + '/login', {}, httpOptions).pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  showToaster(type, message) {
    this.toastr.clear();

    const configSetting = {
      tapToDismiss: false,
      closeButton: true,
      timeOut: 3000
    };

    setTimeout(() => {
      switch (type) {
        case AppConstants.Success:
          this.toastr.success(message, '', configSetting);
          break;
        case AppConstants.Error:
          this.toastr.error(message, '', configSetting);
          break;
        default:
          break;
      }
    }, 50);
  }
}
