// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Option } from './option';
import { sortByStringField } from '../utility/helper-function';
import { DependenciesService } from 'src/app/services/dependencies.service';

@Component({
    selector: 'app-option-filter',
    templateUrl: './option-filter.component.html',
    styleUrls: ['./option-filter.component.scss'],
})
export class OptionFilterComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() selectedValue: string;
    @Input() options: Option[];
    @Input() showIssueCount = false;
    @Input() showDependencyCount = false;
    @Input() showRepoOnly = false;
    @Output() didSelect = new EventEmitter<any>();
    selectedOption: Option;

    constructor(
        private dependenciesService: DependenciesService) { }

    ngOnInit() { }

    ngOnChanges() {
        if (this.selectedValue) {
            this.selectedOption = this.options.find(op => op.value === this.selectedValue);
        } else {
            this.selectedOption = this.options[0];
        }

        this.shiftSelectedRepoAtFirstPosition(this.selectedOption);
    }

    parentClicked(option) {
        this.shiftSelectedRepoAtFirstPosition(option);
        this.didSelect.emit({ option: option, pathOption: { value: '' } });
    }

    childClicked(child: any, option) {
        event.stopPropagation();
        this.shiftSelectedRepoAtFirstPosition(option);

        this.didSelect.emit({ option: option, pathOption: child });
    }

    showIssuesDetails(text, option, isFolderPath, parentRepoText) {
        text = this.setRepoFolderPathName(text, parentRepoText, option);

        if (this.showIssueCount && option) {
            const textToShow = (issuesCountText) => {
                return `${text} <span class="float-right issue-color">${issuesCountText}</span>`;
            };

            if (isFolderPath) {
                option.fixedIssuesCount = option.totalFixedIssueCount;
                option.openIssuesCount = option.totalOpenIssueCount;
            }

            if (!option.fixedIssuesCount && !option.openIssuesCount) {
                return text;
            }

            if (!isFolderPath) {
                return textToShow(`total ${option.openIssuesCount} open + ${option.fixedIssuesCount} fixed issues`);
            }

            return textToShow(`(${option.openIssuesCount} open + ${option.fixedIssuesCount} fixed) issues`);
        } else if (this.showDependencyCount && option) {
            return `${text}
             <span class="float-right dependency-count-color">${option.totalDependencyCount +
                (option.totalDependencyCount === 1 ? ' dependency' : ' dependencies')}</span>`;
        }

        return text;
    }

    shiftSelectedRepoAtFirstPosition(option) {
        if (this.showIssueCount) {
            this.options = this.dependenciesService.sortReposByIssuesCount(this.options);
        } else if (this.showDependencyCount) {
            this.options = this.dependenciesService.sortReposByDependencyCount(this.options);
        } else {
            this.options = sortByStringField(this.options, 'text', 'asc');
        }

        const selectedRepoIndex = this.options.findIndex(op => op.value === option.value);

        if (selectedRepoIndex && selectedRepoIndex > -1) {
            this.options.unshift(this.options.splice(selectedRepoIndex, 1)[0]);
        }
    }

    setRepoFolderPathName(folderPath, repoName, option) {
        // show root('/') if repo name matches to folder path
        // else prepend / before folder path
        folderPath = (folderPath === repoName ? '/' : folderPath);

        // appended package manager
        folderPath += option.packageManager ? ' - (' + option.packageManager + ')' : '';

        return folderPath;
    }
}
