// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit } from "@angular/core";
import { SummaryInfo } from "../../models/project.model";
import { ProjectService } from "src/app/services/project.service";
import { Observable } from "rxjs/internal/Observable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EpochToDateTime, sortByStringField } from "../utility/helper-function";
import { ScannedStatus } from 'src/config/enums/project-scanned-status.enum';
import { AppConstants } from "src/config/constants/app-constants";

@Component({
    selector: "app-vulnerability",
    templateUrl: "./vulnerability.component.html",
    styleUrls: ["./vulnerability.component.scss"]
})
export class VulnerabilityComponent implements OnInit {
    @Input() ProjectCard!: any;
    @Input() OverviewCard: any;
    @Input() error = false;
    projectDate = new Date();
    vulnerabilities$: Observable<SummaryInfo>;
    isApiLoaded = false;
    isScanDetailsApiLoaded = false;
    scanDetails = [];
    repoScannedStatus = '';
    showScanDetails = true;
    hideScanDateTime = false;

    constructor(
        private projectService: ProjectService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.getProjectVulnerabilityDetails();
    }

    seeDetails(content: any) {
        this.isScanDetailsApiLoaded = false;
        this.scanDetails = [];

        if (!this.ProjectCard || !this.ProjectCard.projectId) {
            this.isScanDetailsApiLoaded = true;
            return;
        }

        this.modalService.open(content,
            {
                centered: true,
                size: "lg",
                windowClass: 'scan-details'
            });

        this.projectService.getProjectRepositoriesScanDetails(this.ProjectCard.projectId).subscribe(result => {
            if (result) {
                const dataToSort = sortByStringField(result[this.ProjectCard.projectId] || [], 'repository_name', 'asc');
                this.scanDetails = dataToSort;
            }

            this.isScanDetailsApiLoaded = true;
        }, (err) => {
            this.isScanDetailsApiLoaded = true;
        });
    }

    getProjectVulnerabilityDetails() {
        if (!this.ProjectCard || !this.ProjectCard.projectId) {
            return;
        }

        this.showScanDetails = true;
        this.projectService.getSummaryReport(this.ProjectCard.projectId).subscribe(apiResult => {
            if (!apiResult) {
                this.isApiLoaded = true;
                return;
            }

            const vulnerabilityData = apiResult[this.ProjectCard.projectId];

            this.ProjectCard.vulnerability = vulnerabilityData;
            if (this.ProjectCard.vulnerability && this.ProjectCard.vulnerability.updated_at) {
                this.ProjectCard.vulnerability.updated_at = EpochToDateTime(this.ProjectCard.vulnerability.updated_at);
            }

            this.showScanMessage(this.ProjectCard.vulnerability.message, this.ProjectCard.vulnerability);
            this.isApiLoaded = true;
        }, (err) => {
            this.isApiLoaded = true;
            this.showScanMessage();
        });
    }

    showScanMessage(scanStatus = '', vulnerability = null) {
        this.hideScanDateTime = false;

        // for SCAN_FAILED && updated_at IS NULL
        if (vulnerability && !vulnerability.updated_at) {
            this.repoScannedStatus = AppConstants.Scan_Failed_No_Last_Scan;
            return;
        }

        switch (scanStatus) {
            case ScannedStatus.NotYetScanned:
                this.repoScannedStatus = AppConstants.Project_Not_Scanned_Yet;
                this.showScanDetails = false;
                break;
            case ScannedStatus.NoRepositoryFound:
                this.repoScannedStatus = AppConstants.No_Repository_Found;
                this.showScanDetails = false;
                break;
            case ScannedStatus.ScanFailed:
                this.repoScannedStatus = AppConstants.Scan_Failed;
                this.hideScanDateTime = true;
                break;
            case ScannedStatus.ScanPartiallyCompleted:
                const total_repositories = this.ProjectCard.vulnerability.total_repositories || 0;
                const total_scanned_repositories = this.ProjectCard.vulnerability.total_scanned_repositories || 0;
                this.repoScannedStatus = AppConstants.Scan_Partially_Completed
                    .replace(AppConstants.totalRepositoriesReplaceKey, total_repositories)
                    .replace(AppConstants.totalScannedRepositoriesReplaceKey, total_scanned_repositories);
                this.hideScanDateTime = true;
                break;
            case ScannedStatus.ScanSuccessfullyCompleted:
                this.repoScannedStatus = AppConstants.Scan_Successfully_Completed;
                this.showScanDetails = false;
                this.hideScanDateTime = true;
                break;

            default:
                this.repoScannedStatus = AppConstants.No_Repository_Found;
                this.showScanDetails = false;
                break;
        }
    }

    openRepoUrl(repository_url) {
        window.open(repository_url);
    }
}
