// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

export class Option {
    text: string;
    value: string;
    folderPaths: any[];
    fixedIssuesCount?: number;
    openIssuesCount?: number;
    totalIssueCount?: number;
    totalDependencyCount?: number;

    constructor(
        text: string,
        value: string,
        folderPaths: any[] = [],
        fixedIssuesCount: number = 0,
        openIssuesCount: number = 0,
        totalIssueCount: number = 0,
        totalDependencyCount: number = 0) {
        this.text = text;
        this.value = value;
        this.folderPaths = folderPaths;
        this.fixedIssuesCount = fixedIssuesCount;
        this.openIssuesCount = openIssuesCount;
        this.totalIssueCount = totalIssueCount;
        this.totalDependencyCount = totalDependencyCount;
    }
}
