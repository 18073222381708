// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { ProjectData } from '../models/create_project';
import role from '../../../src/skills.json';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

interface PresignedPutResponse {
  uploadUrl: string;
  destinationUrl: string;
  contentType: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApplyService {
  roles = role;
  selector = 1;
  select_repository;
  constructor(private http: HttpClient, private authService: AuthService) { }
  protocol = '//';
  createForm(project: ProjectData): Observable<ProjectData> {
    const route = this.protocol + environment.vdAPIEndpointHostname + '/vdproject';
    return this.http.post<any>(route,
      project)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadFile(file: File): Observable<string> {

    if (!file) {
      return of('');
    }

    return this.getPresignedUrl(file).pipe(
      flatMap(url => {
        return this.uploadToPresignedUrl(url.uploadUrl, url.contentType, file).pipe(map(_ => url.destinationUrl));
      })
    );
  }

  private getPresignedUrl(file: File): Observable<PresignedPutResponse> {
    const route = environment.vdAPIEndpointHostname + '/vdproject/upload/presigned-url';
    const httpOptions = {
      headers: this.authService.getRequestHeaders(),
    };
    const body = {
      contentType: file.type,
      name: file.name.replace(/(.*)(?=\.\w*$)/gi, '').toLowerCase(),
      size: file.size,
    };
    return this.http.post<PresignedPutResponse>(`${this.protocol}${route}`, body, httpOptions) as Observable<PresignedPutResponse>;
  }

  private uploadToPresignedUrl(url: string, contentType: string, file: File): Observable<Object> {
    const headers = new HttpHeaders({
      'x-amz-acl': 'public-read',
      'content-type': contentType,
    });
    return this.http.put(url, file, { headers });
  }

  isNameValid(name: any): Observable<any> {
    const route = this.protocol + environment.vdAPIEndpointHostname + '/vdproject/name-check';
    return this.http.post<any>(route, name).pipe(catchError(this.handleError));
  }
  storeStepper(step) {
    localStorage.setItem('step', step);
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
