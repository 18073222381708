// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

import { ProjectService } from './services/project.service';
import { Repository } from './models/repository';
import { Subject } from 'rxjs';
import { sortByStringField } from './shared/utility/helper-function';
import { Option } from 'src/app/shared/option-filter/option';

@Injectable()
export class Globals {
    fundspringHostname = environment.fundspringHostname;
    fundspringProjectPath = environment.fundspringProjectPath;
    vdAPIEndpointHostname = environment.vdAPIEndpointHostname;
    vdAPIEndpointProjectPath = environment.vdAPIEndpointProjectPath;
    vdAPIEndpointPath = {
        dependencies: environment.vdAPIEndpointDependenciesPath || 'vulnerability/dependencies',
        dependencyGraph: environment.vdAPIEndpointDependencyGraphPath || 'vulnerability/dependencies/tree',
        licenses: 'licenses',
        issues: 'issues',
        summary: environment.vdAPIEndpointSummaryPath || 'vulnerability/summary',
        overview: 'vulnerability/overview',
        history: 'scan-history',
        task: environment.vdAPIEndpointTaskPath || 'task',
        'staticanalysis/issues': environment.vdAPIEndpointStaticIssuesPath || 'staticanalysis/issues',
        'staticanalysis/summary': environment.vdAPIEndpointStaticSummaryPath || 'staticanalysis/summary',
    };

    protocol = '//';
    projectInfo = null;
    vdAppLoaded = false;
    scrollingCallBack = null;
    scrollingCallBackObject = {};
    repositories: Repository[];
    pathId = '';

    projectLoad = new Subject();

    constructor(private projectService: ProjectService) { }

    initializeProject(externalId: string) {
        return new Promise((res, rej) => {
            this.projectService
                .getProjectInfo(
                    this.protocol + [this.vdAPIEndpointHostname, this.vdAPIEndpointProjectPath].join('/'),
                    externalId
                )
                .subscribe(data => {
                    this.projectInfo = data;
                    this.repositories = this.projectInfo.repositories.map(json => new Repository(json));

                    // first sorted by repo name
                    this.repositories = sortByStringField(this.repositories, 'name', 'asc');
                    if (this.repositories && this.repositories.length > 0 && this.repositories[0].folderPaths
                        && this.repositories[0].folderPaths.length > 0) {
                        this.pathId = this.repositories[0].folderPaths[0].repositoryFolderpathId;
                    }
                    this.vdAppLoaded = true;
                    this.projectLoad.next();
                    res(this.projectInfo);
                }, (error => {
                    rej(error);
                }));
        });
    }


    getParamsFromFilters(issueFilters: object) {
        // TODO: refactor this code into custom-filter service
        // and remove this when is no longer used

        let instanceAllTrue = true;
        let instanceArray = null;
        const searchParamObject = {};

        for (const issueFilterType of Object.keys(issueFilters)) {
            instanceAllTrue = true;
            instanceArray = [];
            searchParamObject[issueFilterType] = '';

            if (typeof issueFilters[issueFilterType] === 'number') {
                instanceAllTrue = false;
                instanceArray.push(issueFilters[issueFilterType]);
            } else {
                for (const issueFilterTypeValue of Object.keys(issueFilters[issueFilterType])) {
                    if (!issueFilters[issueFilterType][issueFilterTypeValue]) {
                        instanceAllTrue = false;
                    } else {
                        instanceArray.push(issueFilterTypeValue);
                    }
                }
            }

            if (instanceAllTrue || !instanceArray.length) {
                delete searchParamObject[issueFilterType];
            } else {
                searchParamObject[issueFilterType] = instanceArray.join(',');
            }
        }
        return searchParamObject;
    }

    updateFilterLanguage(filterObject: object) {
        // TODO: refactor this code into custom-filter service
        // and remove this when is no longer used

        let filtersString = '';
        let shouldShowAllSelected = true;

        for (const filterType in filterObject) {
            let allSelectedForFilterType = true;
            let selectedCountForFilterType = 0;
            for (const filters in filterObject[filterType]) {
                if (!filterObject[filterType][filters]) {
                    allSelectedForFilterType = false;
                    continue;
                }

                selectedCountForFilterType++;
            }

            if (selectedCountForFilterType === 0) {
                shouldShowAllSelected = false;
                continue;
            }

            if (!allSelectedForFilterType) {
                shouldShowAllSelected = false;
            }

            if (filtersString) {
                filtersString += ', ';
            }

            filtersString += selectedCountForFilterType + ' ' + filterType;
        }

        if (shouldShowAllSelected) {
            filtersString = 'All';
        }

        if (!filtersString) {
            filtersString = 'None';
        }

        return filtersString;
    }

    getFundspringProjectUrl(fundsprintId: string) {
        return this.protocol + [this.fundspringHostname, this.fundspringProjectPath, fundsprintId].join('/');
    }

    getApiHostname(externalId: string) {
        return this.protocol + [this.vdAPIEndpointHostname, 'v1', this.vdAPIEndpointProjectPath, externalId].join('/');
    }

    getApiPath(pathName: string) {
        return this.vdAPIEndpointPath[pathName];
    }

    getAppLoaded() {
        return this.vdAppLoaded;
    }

    getExternalId() {
        return this.projectInfo && this.projectInfo.externalId;
    }

    getProjectInfo() {
        return this.projectInfo;
    }

    checkForProjectInfo(externalId: string) {
        return new Promise((res, rej) => {
            if (this.projectInfo === null) {
                res(this.initializeProject(externalId));
            } else {
                this.projectLoad.next();
                res(this.projectInfo);
            }
        });
    }

    getPathId() {
        return this.projectInfo && this.projectInfo.pathId;
    }

    startScrollingListener() {
        let footer,
            footerOffsetHeight,
            self = this;

        window.onscroll = function () {
            let currentDataTypeElement, currentDataType;

            if (!footer) {
                footer = document.getElementById('footer');
            }

            if (!footerOffsetHeight) {
                footerOffsetHeight = footer.offsetHeight;
            }

            if (window.scrollY + window.innerHeight > document.body.offsetHeight - footerOffsetHeight) {
                if (
                    (currentDataTypeElement = document.getElementById('currentDataDisplay')) &&
                    (currentDataType = currentDataTypeElement.getAttribute('data-table-type'))
                ) {
                    self.scrollingCallBackObject &&
                        self.scrollingCallBackObject[currentDataType] &&
                        self.scrollingCallBackObject[currentDataType]();
                }
            }
        };
    }

    updatePaginationCallback(callbackPropertyName: string, scrollingCallBack: object) {
        this.scrollingCallBackObject[callbackPropertyName] = scrollingCallBack;
    }

    getRepositories() {
        return new Promise((resolve, reject) => {
            this.projectLoad.subscribe(() => {
                const repositories = this.repositories.map(repo =>
                    new Option(repo.name, repo.repositoryId, repo.folderPaths, repo.fixedIssuesCount, repo.openIssuesCount));

                resolve(repositories);
            });
        });
    }

    // new APIs

    getV1ApiHostname(externalId: string) {
        return this.protocol + [this.vdAPIEndpointHostname, 'v1', this.vdAPIEndpointProjectPath, externalId].join('/');
    }
}
