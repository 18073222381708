// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

export function sortByStringField(array: any[], key: any, sortType = '', sortByLowerCase = false) {
    if (!array || !array.length) {
        return array;
    }

    return array.sort((a, b) => {
        if (sortByLowerCase) {
            b[key] = b[key].toLowerCase();
            a[key] = a[key].toLowerCase();
        }

        if (sortType === 'desc') {
            if (b[key] < a[key]) { return -1; }
            if (b[key] > a[key]) { return 1; }
        } else {
            if (a[key] < b[key]) { return -1; }
            if (a[key] > b[key]) { return 1; }
        }

        return 0;
    });
}

export function sort(array = [], sortType = '') {
    if (!array && !array.length) {
        return array;
    }

    return array.sort((a, b) => {
        if (sortType === 'asc') {
            return a - b;
        } else if (sortType === 'desc') {
            return b - a;
        } else {
            return a - b;
        }
    });
}

export function EpochToDateTime(inputEpoch) {
    if (!inputEpoch) {
        inputEpoch = 0;
    }

    return new Date(Number(inputEpoch) * 1000);
}
