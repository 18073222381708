// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

export class ProjectData {
    projectID: string;
    lfID: string;
    name: string;
    status: string;
    industry: string;
    description: string;
    color: string;
    repoLink: string;
    websiteURL: string;
    logoURL: string;
    codeOfConduct: string;
    projectCllProjectID: string;
    slug: string;
    allowContributorAccess: boolean;
    contributors: any;
    constructor() {
    }
}
