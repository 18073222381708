// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { PathOption } from './path-option';

@Component({
    selector: 'path-option-filter',
    templateUrl: './path-option-filter.component.html',
    styleUrls: ['./path-option-filter.component.scss'],
})
export class PathOptionFilterComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() selectedValue: string;
    @Input() options: PathOption[];

    selectedOption: PathOption;

    @Output() didSelect = new EventEmitter<PathOption>();

    constructor() {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.selectedValue) {
            this.selectedOption = this.options.find(op => op.value === this.selectedValue);
        } else {
            this.selectedOption = this.options[0];
        }
    }

    pathOptionClicked(option: PathOption) {
        this.selectedOption = option;
        this.didSelect.emit(option);
    }
}
