// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import * as auth0 from 'auth0-js';
import { RepositoryService } from './services/repository.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loadPage = false;

  constructor(
    private router: Router,
    public auth: AuthService,
    public userService: UserService,
    private repositoryService: RepositoryService) { }

  ngOnInit() {
    this.redirecttoapply();
    this.auth.initialAuthCheck.subscribe(authResult => {
      this.auth.isLoggedIn().subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.userService.loginUser().subscribe(result => {
            localStorage.removeItem('email');
            localStorage.removeItem('name');
            // Set local storage flags and skills list if user is mentor/apprentice
            localStorage.removeItem('userId');
            localStorage.removeItem('lfId');
            localStorage.setItem('name', result.name);
            localStorage.setItem('email', result.email);
            localStorage.setItem('userId', result.userId);
            localStorage.setItem('lfId', result.lfid);
            this.redirectWithAuth0State(authResult);
            this.loadPage = true;
          }, () => {
            this.loadPage = true;
          });
        } else {
          localStorage.clear();
          this.redirectWithAuth0State(authResult);
          this.loadPage = true;
        }
      }, () => {
        this.loadPage = true;
      });
    }, () => {
      this.loadPage = true;
    });
    this.auth.handleAuthentication();
    this.auth.scheduleRenewal();
  }

  /**
 * Extracts the redirect url from the Auth0 state and navigates
 * to the redirect url.
 * @param authResult The Auth0DecodedHash or null.
 */
  private redirectWithAuth0State(authResult: auth0.Auth0DecodedHash | null) {
    if (!(authResult && authResult.state)) {
      return;
    }
    const redirectPath = authResult.state;
    this.router.navigateByUrl(redirectPath);
  }
  redirecttoapply() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      this.auth.initialAuthCheck.subscribe(res => {
        this.repositoryService.code = code;
        this.router.navigate(['apply/select-repo']);
      });
    }
  }
}
