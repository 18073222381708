// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ProjectService } from 'src/app/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/config/constants/app-constants';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  isLoggedIn;
  projectId;
  validUser = false;
  loggedUserId;

  constructor(private authService: AuthService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private globals: Globals,
    private router: Router) { }

  ngOnInit() {
    this.loggedUserId = this.authService.getUserId();
    this.projectId = this.route.snapshot.params.externalId;

    this.authService.isLoggedIn().subscribe((login: Boolean) => {
      this.isLoggedIn = login;
      if (this.isLoggedIn && this.projectId) {
        this.projectService.getVDProjectDetailsByProjectId(this.projectId).subscribe(result => {
          this.checkIfValidUser(result);
        }, (() => {
          this.router.navigate(['']);
          this.validUser = false;
        }));
      } else {
        this.router.navigate(['']);
      }
    });
  }

  checkIfValidUser(data) {
    const projectDetails: any = data;

    if (projectDetails && this.projectService.isMaintainerOrContributor(projectDetails)) {
      this.validUser = true;
    } else {
      this.router.navigate(['']);
      this.validUser = false;
    }
  }

  ngOnDestroy() {
    this.globals.projectInfo = null;
    this.globals.repositories = null;
    this.globals.pathId = '';
  }

}
