// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApplyService } from '../../services/apply.service';
import { UserService } from 'src/app/services/user.service';
import { AppConstants } from 'src/config/constants/app-constants';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() pageName = '';
  @Input() logo = '/assets/imgs/cp_app_vd-w.svg';
  @Input() help = '/assets/imgs/ico_cb_help.svg';
  isScrolled: boolean;
  isLoggedIn = false;

  constructor(
    private globals: Globals,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private applyService: ApplyService) {
    authService.isLoggedIn().subscribe(isLogin => {
      this.isLoggedIn = isLogin;
    });
  }

  ngOnInit() {
    if (window.scrollY === 0) {
      this.isScrolled = false;
    } else {
      this.isScrolled = true;
    }
    window.onscroll = () => {
      if (window.scrollY === 0) {
        this.isScrolled = false;
      } else {
        this.isScrolled = true;
      }
    };
  }
  setStepper() {
    this.applyService.storeStepper(1);
  }

  returnToCommunityFunding() {
    let url = environment.CommunityFunding;

    const externalId = this.globals.getExternalId();
    if (externalId && externalId !== "") {
      url += `/projects/${externalId}`;
    }

    window.open(url, '_self');
  }

  onLogIn() {
    // this.isCollapsed = true;
    this.authService.login();
  }

  onLogOut() {
    this.userService.showToaster(AppConstants.Success, AppConstants.Logged_Out_Success);
    setTimeout(() => {
      this.authService.logout();
    }, 1500);
  }

  goToPage(url: string, fragment?: string, external?: boolean) {
    if (external) {
      window.open(url, '_self');
    } else if (url === 'profile') {
      window.open(environment.CommunityFunding + '/account/profile', '_self');
    } else {
      if (this.router && this.router.url === '/') {
        window.location.reload();
      } else {
        this.router.navigate([url], { fragment: fragment });
      }
    }
  }

  navigateToApply() {
    if (!this.isLoggedIn) {
      this.authService.login(`/apply`);
    } else {
      this.router.navigate(['/apply']);
    }
  }
}
