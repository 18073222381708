// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
export class AppConstants {
  public static Chart_SCANS_MSG =
    'The current chart is showing single set of data points. ' +
    'The chart will show more details ' +
    'once more security scans are complete and additional data is available.';

  // keys or labels here
  public static Overview = 'overview';
  public static True = 'true';
  public static AuthClientId = 'auth0-clientId';
  public static Success = 'success';
  public static Error = 'error';
  public static Info = 'info';
  public static Warning = 'warning';

  // text messages here
  public static Logged_Out_Success = 'logged out successfully';

  public static totalRepositoriesReplaceKey = '#total_repositories#';
  public static totalScannedRepositoriesReplaceKey = '#total_scanned_repositories#';

  public static Project_Not_Scanned_Yet = 'Project onboarded but not scanned yet';
  public static No_Repository_Found = 'No repository found for this project to be scanned';
  // tslint:disable-next-line: max-line-length
  // public static Scanned_Failed = 'At least one repository enabled for this project & Scan failed for all repositories of the project';
  public static Scan_Failed = 'Failure to scan repositories in this project';
  public static Scan_Failed_No_Last_Scan = 'Failure to scan repositories in this project, No last scan results';
  // tslint:disable-next-line: max-line-length
  public static Scan_Partially_Completed = 'Out of #total_repositories#, #total_scanned_repositories# repositories scanned successfully';
  public static Scan_Successfully_Completed = 'All repositories scanned successfully';




  // tslint:disable-next-line: max-line-length
  public static Warning_While_Disabling_Repository = 'Please be careful when deactivating scan on this repository as other repositories depend on it and deactivating scan will affect those dependent repositories';

  public static skipProjects = [
    'linux_kernel_mentorship'
  ];

}
