// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    acceptableUsePolicy: string;
    serviceSpecificTerms: string;
    platformUseAgreement: string;
    privacyPolicy: string;
    trademarkUsage: string;

    ngOnInit() {
        this.acceptableUsePolicy = environment.acceptableUsePolicy;
        this.serviceSpecificTerms = environment.serviceSpecificTerms;
        this.platformUseAgreement = environment.platformUseAgreement;
        this.privacyPolicy = environment.privacyPolicy;
        this.trademarkUsage = environment.trademarkUsage;
    }
}
