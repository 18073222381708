// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApproveService } from '../../services/approve.service';
import { ProjectData } from '../../models/create_project';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnInit {
  projectId: string;
  project = new ProjectData();
  constructor(private route: ActivatedRoute, private approveService: ApproveService, private router: Router) {
    this.projectId = this.route.snapshot.params.id;
    if (this.projectId) {
      this.viewProject();
    } else {
      const token = this.route.snapshot.queryParams.jwt;
      this.approveProject(token);
    }

  }

  ngOnInit() {
  }

  approveProject(token: string) {
    if (token) {
      this.approveService.approve_project(token).subscribe(response => {
        this.router.navigate(['']);
      }, err => this.router.navigate(['']));
    }
  }

  viewProject() {
    this.approveService.viewProject(this.projectId).subscribe(response => {
      this.project = response;
      this.project.contributors = this.project.contributors.filter(x => x.userType !== 'maintainer');
    });
  }
}
