// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { CustomFilter } from '../shared/custom-filter/custom-filter';

@Injectable({
    providedIn: 'root',
})
export class CustomFilterService {
    constructor() {}

    getUrlParams(filters: CustomFilter) {
        let allFiltersTrue = true;
        let filterArray = null;
        const searchParamObject = {};

        for (const filterGroupKey of Object.keys(filters)) {
            allFiltersTrue = true;
            filterArray = [];
            searchParamObject[filterGroupKey] = '';

            for (const filterKey of Object.keys(filters[filterGroupKey].data)) {
                if (!filters[filterGroupKey].data[filterKey].data) {
                    allFiltersTrue = false;
                } else {
                    filterArray.push(filterKey);
                }
            }

            // if all flags are true OR all are are false, don't pass those params
            if (allFiltersTrue || !filterArray.length) {
                delete searchParamObject[filterGroupKey];
            } else {
                searchParamObject[filterGroupKey] = filterArray.join(',');
            }
        }
        return searchParamObject;
    }

    getFilterText(filters: CustomFilter) {
        let filtersString = '';
        let shouldShowAllSelected = true;

        for (const filterGroupKey of Object.keys(filters)) {
            let allSelectedForFilterType = true;
            let selectedCountForFilterType = 0;
            for (const filterKey of Object.keys(filters[filterGroupKey].data)) {
                if (!filters[filterGroupKey].data[filterKey].data) {
                    allSelectedForFilterType = false;
                    continue;
                }

                selectedCountForFilterType++;
            }

            if (selectedCountForFilterType === 0) {
                shouldShowAllSelected = false;
                continue;
            }

            if (!allSelectedForFilterType) {
                shouldShowAllSelected = false;
            }

            if (filtersString) {
                filtersString += ', ';
            }

            filtersString += selectedCountForFilterType + ' ' + filterGroupKey;
        }

        if (shouldShowAllSelected) {
            filtersString = 'All';
        }

        if (!filtersString) {
            filtersString = 'None';
        }

        return filtersString;
    }
}
