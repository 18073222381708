// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, ReplaySubject, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import * as auth0 from 'auth0-js';
import { environment } from '../../environments/environment';
import { EnvConfig } from './vd.env.utils';
import { AppConstants } from 'src/config/constants/app-constants';

@Injectable()
export class AuthService {
  private _idToken: string;
  private _accessToken: string;
  private _expiresAt: number;
  private isLoginSubject = new BehaviorSubject<boolean>(this.loginStatus());
  public userProfile: any;
  refreshSubscription: any;
  initialAuthCheck = new ReplaySubject<auth0.Auth0DecodedHash | null>(1);
  private env = EnvConfig;
  auth0 = new auth0.WebAuth({
    clientID: this.env.default['auth0-clientId'],
    domain: this.env.default['auth0-domain'],
    responseType: 'token id_token code',
    redirectUri: environment.AUTH_REDIRECT_URL,
    responseMode: 'fragment',
    scope: 'openid profile email'
  });

  constructor(
    public router: Router,
  ) {
    this._idToken = '';
    this._accessToken = '';
    this._expiresAt = 0;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  get idToken(): string {
    return this._idToken;
  }

  getRequestHeaders(headers: any = {}) {
    if (this.idToken) {
      headers.Authorization = 'Bearer ' + this.idToken;
    }
    return new HttpHeaders(headers);
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  public login(path: string = '/'): void {
    const options: any = {
      state: path || '/',
    };
    this.auth0.authorize(options);
  }

  public handleAuthentication(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = '';
          this.localLogin(authResult);
          this.initialAuthCheck.next(authResult);
          this.initialAuthCheck.complete();
          return resolve(authResult);
        }
        if (err) {
          return resolve({});
        }

        if (this.isLoggedIn()) {
          return this.renewTokens();
        }

        this.initialAuthCheck.next(null);
        this.initialAuthCheck.complete();
      });
    });
  }

  private localLogin(authResult: any): void {

    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this._accessToken = authResult.accessToken;
    this._idToken = authResult.idToken;
    this._expiresAt = expiresAt;

    this.scheduleRenewal();

    // Set isLoggedIn flag in localStorage
    // do this after setting variables
    localStorage.setItem('isLoggedIn', 'true');
    this.isLoginSubject.next(true);
  }

  public renewTokens(): void {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.localLogin(authResult);
      } else if (err) {
        console.log(`Could not get a new token (${err.error}: ${(<any>err).error_description}).`);
        this.logout(false);
      }
      if (authResult) {
        authResult.state = null;
      }
      this.initialAuthCheck.next(authResult);
      this.initialAuthCheck.complete();
    });
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) { return; }
    this.unscheduleRenewal();

    const expiresIn$ = of(this._expiresAt).pipe(
      mergeMap(
        expiresAt => {
          const now = Date.now();
          // Use timer to track delay until expiration
          // to run the refresh at the proper time
          return timer(Math.max(1, expiresAt - now));
        }
      )
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = expiresIn$.subscribe(
      () => {
        this.renewTokens();
        this.scheduleRenewal();
      }
    );
  }

  public unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  public logout(redirect: boolean = true): void {
    // Remove tokens and expiry time
    this._accessToken = '';
    this._idToken = '';
    this._expiresAt = 0;
    this.unscheduleRenewal();

    // Remove localStorage items
    localStorage.clear();

    this.isLoginSubject.next(false);
    if (redirect) {
      // this.createLogoutIframe();
      this.logoutFromAuthZero();
    }
  }

  public getProfile(cb: any): void {
    this.auth0.checkSession({}, (err, authResult) => {
      if (!authResult.accessToken) {
        throw new Error('Access Token must exist to fetch profile');
      }

      const self = this;
      this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
        if (profile) {
          self.userProfile = profile;
        }
        cb(err, profile);
      });
    });
  }

  private createLogoutIframe() {
    const doc = window.document;
    const body = doc.getElementsByTagName('body')[0];
    const iframe = doc.createElement('iframe');
    iframe.src = `https://${this.env.default['auth0-domain']}/v2/logout?client_id=${this.env.default[AppConstants.AuthClientId]}&returnTo=${decodeURIComponent(environment.AUTH_REDIRECT_URL)}`;
    iframe.style.display = 'none';
    body.appendChild(iframe);

    setTimeout(() => {
      body.removeChild(iframe);
    }, 5000);
  }

  private logoutFromAuthZero() {
    this.auth0.logout({
      client_id: this.env.default[AppConstants.AuthClientId],
      // returnTo: environment.AUTH_REDIRECT_URL
      returnTo: decodeURIComponent(environment.AUTH_REDIRECT_URL)
    });
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    return new Date().getTime() < this._expiresAt;
  }

  public getUserId(): string {
    if (this.loginStatus()) {
      return localStorage.getItem('userId');
    }
  }

  private loginStatus() {
    return !!localStorage.getItem('isLoggedIn');
  }

  public getLoggedUserEmail(): string {
    if (this.loginStatus()) {
      let email = localStorage.getItem('email');
      if (email) {
        email = email.toLowerCase();
      }

      return email;
    }
  }
}
