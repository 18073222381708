// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Globals } from '../../globals';
import { CustomFilter } from 'src/app/shared/custom-filter/custom-filter';
import { Subscription } from 'rxjs';
import { IssuesService } from 'src/app/services/issues.service';
import { CustomFilterService } from 'src/app/services/custom-filter.service';
import { Issue } from 'src/app/models/issues';
import { Option } from 'src/app/shared/option-filter/option';
import { PathOption } from 'src/app/shared/path-option-filter/path-option';
import { sortByStringField } from 'src/app/shared/utility/helper-function';
import { DependenciesService } from 'src/app/services/dependencies.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
    details: Issue[];
    detailsLoaded = false;
    projectInfo = null;
    projectIntervalHolder = null;
    detailsFilters: CustomFilter = null;
    detailIds = '';
    detailFiltersString = '';
    repositories: Option[] = [];
    repositoryId: string;
    repositoryFolderpathId: string;
    isLoadingDependencyGraph = false;
    isRepoSelected = false;
    checkProject = false;
    snykIds = '';
    selectedSeverity = '';
    private subscription: Subscription;
    private projectId: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private issuesService: IssuesService,
        private globals: Globals,
        private customFilterService: CustomFilterService,
        private projectService: ProjectService,
        private dependenciesService: DependenciesService) { }

    getDependencyIssues(): void {
        const params: any = this.customFilterService.getUrlParams(this.detailsFilters);
        if (this.detailIds) {
            params.issueID = this.detailIds;
        }

        if (this.repositoryFolderpathId) {
            params.pathID = this.repositoryFolderpathId;
        }
        this.detailsLoaded = false;
        this.issuesService.getDependencyIssues(this.projectId, this.repositoryId, params).subscribe(data => {
            if (data) {
                this.details = data.map(json => new Issue(json));
                this.filterIssuesIfRedirectedFromDependencyTreePage();
                this.selectSeverityInFiltersIfNavigatedFromDependencyTree();
            }
            this.detailsLoaded = true;
        }, (err) => {
            this.detailsLoaded = true;
        });
        this.isRepoSelected = false;
    }

    filterIssuesIfRedirectedFromDependencyTreePage() {
        if (this.snykIds && this.details && this.details.length) {
            const snykIds = this.snykIds.split(',').map(d => (d.trim()));
            this.details = this.details.filter(x => snykIds.includes(x.snykId));
        }
    }

    repoSelected(payload: any) {
        this.repositoryId = payload.option.value;
        this.isRepoSelected = true;
        this.folderPathSelected(payload.pathOption);

        if (payload) {
            this.projectService.showRepositoryDetailsAfterProjectName(payload && payload.option,
                payload && payload.pathOption && payload.pathOption.value, true);
        }
    }


    folderPathSelected(pathOption: PathOption) {
        this.repositoryFolderpathId = pathOption.value;
        this.setDefaultFilter();
        this.clearQueryParams();
        this.getDependencyIssues();
        this.isRepoSelected = false;
    }



    updateFilters(): void {
        this.detailFiltersString = this.customFilterService.getFilterText(this.detailsFilters);
        this.clearQueryParams();
        this.getDependencyIssues();
    }

    ngOnInit() {
        this.projectId = this.route.root.snapshot.firstChild.params.externalId;
        this.snykIds = this.route.snapshot.queryParams.ids;
        this.repositoryFolderpathId = this.route.snapshot.queryParams.PathID;
        this.setDefaultFilter();

        this.subscription = this.globals.projectLoad.subscribe(() => {
            this.projectInfo = this.globals.projectInfo;
            const repositories = this.globals.repositories.map(repo =>
                new Option(repo.name, repo.repositoryId, repo.folderPaths, repo.fixedIssuesCount,
                    repo.openIssuesCount, repo.totalIssuesCount));
            this.repositories = this.dependenciesService.sortReposByIssuesCount(repositories);
            this.repositoryId = this.route.snapshot.queryParamMap.get('repositoryId') || this.repositories[0].value;
            this.projectService.showRepositoryDetailsAfterProjectName(
                this.repositories.find(x => x.value === this.repositoryId), null, true);
            this.getDependencyIssues();
        });
        this.globals.checkForProjectInfo(this.projectId).then(data => {
            this.checkProject = false;
        }).catch((err) => {
            this.projectInfo = false;
            this.checkProject = true;
        });
    }

    selectSeverityInFiltersIfNavigatedFromDependencyTree() {
        this.selectedSeverity = this.route.snapshot.queryParams.severity;
        if (this.selectedSeverity) {
            this.detailsFilters.severity.data.high.data = false;
            this.detailsFilters.severity.data.medium.data = false;
            this.detailsFilters.severity.data.low.data = false;
            switch (this.selectedSeverity) {
                case 'high':
                    this.detailsFilters.severity.data.high.data = true;
                    break;
                case 'medium':
                    this.detailsFilters.severity.data.medium.data = true;
                    break;
                case 'low':
                    this.detailsFilters.severity.data.low.data = true;
                    break;

                default:
                    break;
            }
            this.detailFiltersString = this.customFilterService.getFilterText(this.detailsFilters);
        }
    }

    clearQueryParams() {
        // cleared snykids and severity when navigated from dependency tree
        // and selected another repo
        this.snykIds = '';
        this.selectedSeverity = '';

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                repositoryId: this.repositoryId,
                // PathID: this.repositoryFolderpathId,
                ids: '',
                severity: ''
            },
        });
    }

    setDefaultFilter() {
        this.detailsFilters = {
            severity: {
                data: {
                    high: { data: true },
                    medium: { data: true },
                    low: { data: true },
                },
                label: 'Severity',
            },
            status: {
                data: {
                    open: { data: true },
                    fixed: { data: false },
                },
            },
        };

        this.detailFiltersString = this.customFilterService.getFilterText(this.detailsFilters);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
