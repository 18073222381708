// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';
import { ProjectService } from 'src/app/services/project.service';
import { AppConstants } from 'src/config/constants/app-constants';
import { NgbdSortableHeader } from 'src/app/shared/directives/sort-directive.directive';
import { EpochToDateTime } from 'src/app/shared/utility/helper-function';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
  providers: [
    NgbActiveModal,
  ]
})
export class SettingComponent implements OnInit, OnDestroy {
  repositories: any[];
  projectInfo;
  projectId;
  projectLoadSuscription: Subscription;
  projectRepositoriesSuscription: Subscription;
  checkProject = false;
  popupBodyMsg = '';
  isApiLoaded = false;
  isVdProject = '';
  searchText = '';
  limit = 20;
  offset = 0;
  currentPage = 1;
  totalRecords = 0;
  sortAsc = true;
  sortColumn = 'name';
  sortOrder = 'asc';
  lastScanDateTime;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    config: NgbModalConfig,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private globals: Globals,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.projectId = this.route.root.snapshot.firstChild.params.externalId;
    this.isVdProject = this.route.snapshot.queryParams.isvdproject;
    this.getRepositories();
    this.getProjectInfo();
  }

  getRepositories() {
    // tslint:disable-next-line: max-line-length
    this.projectRepositoriesSuscription = this.projectService
      .getProjectRepositories(
        this.projectId,
        this.searchText,
        this.sortColumn,
        this.sortOrder,
        this.offset,
        this.limit).subscribe(result => {
          if (result) {
            this.repositories = result.repositories;
            this.totalRecords = result.pagination && result.pagination.totalRecord;
          }
          this.isApiLoaded = true;
        }, (err) => {
          this.isApiLoaded = true;
        });
  }

  showScanError(content, repository) {
    if (!repository || !repository.error) {
      return;
    }

    if (repository && repository.repositoryId) {
      this.getRepositoryScanDetails(repository, content);
    }
  }

  getProjectInfo() {
    if (!this.projectId) {
      return;
    }

    this.projectLoadSuscription = this.globals.projectLoad.subscribe(() => {
      this.projectInfo = this.globals.projectInfo;
    });

    this.globals.checkForProjectInfo(this.projectId).then(data => {
      this.checkProject = false;
    }).catch((err) => {
      this.projectInfo = false;
      this.checkProject = true;
    });
  }

  navigateToDependencyTreePage(repository) {
    if (repository && !repository.dependencyCount) {
      return;
    }

    if (!repository.enable) {
      return;
    }

    this.router.navigate([this.projectId, 'dependency-tree'],
      {
        queryParams: {
          isvdproject: (this.isVdProject === AppConstants.True),
          repositoryId: repository.repositoryId
        }
      });
  }

  searchRepository() {
    if (this.searchText.length > 2 || this.searchText.length === 0) {
      this.offset = 0;
      this.currentPage = 1;
      this.sortHandling('', '');
      this.loadUrl();
    }
  }

  onPageChange(page) {
    this.offset = (page * this.limit) - this.limit;
    this.loadUrl();
  }

  loadUrl() {
    this.isApiLoaded = false;
    this.repositories = [];

    if (this.projectRepositoriesSuscription) {
      this.projectRepositoriesSuscription.unsubscribe();
    }

    this.getRepositories();
  }

  onSort(sortEvent) {
    // resetting other headers
    this.sortHandling(sortEvent.column, sortEvent.direction);

    this.loadUrl();
  }

  sortHandling(column, direction) {
    this.headers.forEach(header => {
      if (header.sortable.toLowerCase() !== column) {
        header.direction = 'asc';
      }
    });

    this.sortOrder = !direction ? 'asc' : direction;

    if (column) {
      this.sortColumn = column;
    }
  }

  clearSearch() {
    this.searchText = '';
    this.searchRepository();
  }

  getRepositoryScanDetails(repository, content) {
    this.popupBodyMsg = repository.error;
    this.lastScanDateTime = repository.lastScanDate && EpochToDateTime(repository.lastScanDate);
    this.modalService.open(content, { centered: true, windowClass: 'confirmation-status-popup' });
  }

  ngOnDestroy() {
    this.globals.projectInfo = null;
    this.globals.repositories = null;
    this.globals.pathId = '';

    if (this.projectLoadSuscription) {
      this.projectLoadSuscription.unsubscribe();
    }

    if (this.projectLoadSuscription) {
      this.projectRepositoriesSuscription.unsubscribe();
    }
  }
}