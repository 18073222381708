// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { titleCase } from 'change-case';

@Component({
    selector: 'app-custom-filter',
    templateUrl: './custom-filter.component.html',
    styleUrls: ['./custom-filter.component.scss'],
})
export class CustomFilterComponent implements OnInit, OnChanges {
    @Input() label: string;

    private _filters: any;
    filterKeys: string[];
    filterLabels: string[] = [];
    subFilterKeysArr: string[][] = [];
    subFilterLabelsArr: string[][] = [];

    get filters(): any {
        return this._filters;
    }

    @Input()
    set filters(value: any) {
        this._filters = value;
        this.filterKeys = Object.keys(this._filters);
        this.filterKeys.forEach(filterKey => {
            const subObject = this._filters[filterKey];
            this.filterLabels.push(subObject.label || titleCase(filterKey));
            const subFilterKeys = Object.keys(subObject.data);
            this.subFilterKeysArr.push(subFilterKeys);
            const subFilterLabels = [];
            subFilterKeys.map(subFilterKey => {
                const subFilterLabel = this._filters[filterKey].data[subFilterKey].label;
                subFilterLabels.push(subFilterLabel || titleCase(subFilterKey));
            });
            this.subFilterLabelsArr.push(subFilterLabels);
        });
    }

    @Output() update = new EventEmitter<{}>();

    constructor() {}

    ngOnInit() {}

    ngOnChanges() {}

    checkboxChanged() {
        this.update.emit();
    }
}
