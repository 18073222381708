// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { DetailsComponent } from './pages/details/details.component';
import { NotValidPageComponent } from './pages/not-valid-page/not-valid-page.component';
import { DiscoverComponent } from './pages/discover/discover.component';
import { LandingComponent } from './pages/landing/landing.component';
import { RedirectingComponent } from './redirecting/redirecting/redirecting.component';
import { MyProjectsComponent } from './pages/my-projects/my-projects.component';
import { ApproveComponent } from './pages/approve/approve.component';
import { SettingComponent } from './pages/setting/setting.component';
const routes: Routes = [
    {
        path: '',
        component: DiscoverComponent
    },
    {
        path: 'my-projects',
        component: MyProjectsComponent
    },
    {
        path: 'project/:id',
        component: ApproveComponent
    },
    {
        path: 'email/approve-project',
        component: ApproveComponent
    },
    {
        path: 'auth',
        component: RedirectingComponent
    },
    {
        path: 'apply',
        loadChildren: './pages/apply/apply.module#ApplyModule'
    },
    {
        path: ':externalId',
        redirectTo: ':externalId',
        pathMatch: 'full',

    },
    {
        path: ':externalId',
        component: LandingComponent,
        children: [
            {
                path: 'overview',
                loadChildren: './pages/overview/overview.module#OverviewModule',
            },
            {
                path: 'issues', // Renamed to issues due to VD-397
                loadChildren: './pages/issues/issues.module#IssuesModule',
            },
            {
                path: 'licenses',
                loadChildren: './pages/licenses/licenses.module#LicensesModule',
            },
            {
                path: 'dependencies',
                loadChildren: './pages/dependencies/dependencies.module#DependenciesModule',
            },
            {
                path: 'dependency-tree',
                loadChildren: './pages/dependency-tree/dependency-tree.module#DependencyTreeModule',
            },
            {
                path: 'setting',
                component: SettingComponent,
            }
        ],
    },
    {
        path: '**',
        component: NotValidPageComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
