// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-export-csv',
    templateUrl: './export-csv.component.html',
    styleUrls: ['./export-csv.component.scss'],
})
export class ExportCsvComponent implements OnInit {
    @Output() csv = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}

    onClick() {
        this.csv.emit();
    }
}
