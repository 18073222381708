// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectCard } from '../../models/project.model';
import { environment } from '../../../environments/environment';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ProjectService } from 'src/app/services/project.service';
import { AppConstants } from 'src/config/constants/app-constants';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class ProjectCardComponent implements OnInit {
  @Input() projectCard: any = {} as any;
  isLoggedIn: Boolean;
  isAuthorizeUser = false;
  buttonText = 'Login to see details';

  constructor(private router: Router,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private authService: AuthService,
    private projectService: ProjectService) {
    config.backdrop = 'static';
    config.keyboard = true;
  }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe((login: Boolean) => {
      this.isLoggedIn = login;
      if (this.isLoggedIn) {
        const isAuthorized = this.projectService.isMaintainerOrContributor(this.projectCard);
        this.buttonText = isAuthorized ? 'See Details' : 'Request Access';
      }
    });

  }

  onClickViewProject(projectCard: ProjectCard) {
    this.router.navigate(['project/' + projectCard.projectId]);
  }

  onClickDonate(projectCard: ProjectCard) {
    if (projectCard.projectId) {
      window.open(environment.CommunityFunding + '/projects/' + projectCard.projectId + '/payments');
    } else {
      window.open(environment.CommunityFunding + '/projects/' + projectCard.slug + '/payments');
    }
  }

  onClickProjectDetails(projectCard, content) {
    if (!this.isLoggedIn) {
      this.authService.login(`${projectCard.projectId}/overview`);
    } else {
      this.projectService.getVDProjectDetailsByProjectId(projectCard.projectId).subscribe((result: any) => {
        this.checkProjectDetails(projectCard, result, content);
      });
    }
  }

  openPopup(content) {
    this.modalService.open(content, { centered: true });
  }

  onClickJoin() {
    window.open(environment.REQUEST_ACCESS_LINK_TO_JOIN_PROJECT);
    this.modalService.dismissAll();
  }

  checkProjectDetails(projectCard, apiResult, content) {
    const projectDetails: any = apiResult;
    this.isAuthorizeUser = this.projectService.isMaintainerOrContributor(projectDetails);

    if (this.isAuthorizeUser) {
      if (!this.projectCard) {
        this.openPopup(content);
        return;
      }

      this.router.navigate([projectCard.projectId, AppConstants.Overview], {
        queryParams: {
          isvdproject: true
        }
      });
    } else {
      this.isAuthorizeUser = false;
      this.openPopup(content);
    }
  }

  showDescription(descriptionContent) {
    this.modalService.open(descriptionContent, { centered: true });
  }
}

