// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomFilterComponent } from './custom-filter/custom-filter.component';
import { ExportCsvComponent } from './export-csv/export-csv.component';
import { LoaderComponent } from './loader/loader.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { OptionFilterComponent } from './option-filter/option-filter.component';
import { PathOptionFilterComponent } from './path-option-filter/path-option-filter.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { FullscreenLoaderComponent } from './loader/fullscreen-loader/fullscreen-loader.component';
import { NavComponent } from './nav/nav.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { OverviewCardComponent } from './overview-card/overview-card.component';
import { VulnerabilityComponent } from './vulnerability/vulnerability.component';
import { StepperComponent } from './stepper/stepper.component';
import { StepperItemComponent } from './stepper-item/stepper-item.component';
import { TimeAgoPipe } from 'time-ago-pipe';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { NgbdSortableHeader } from './directives/sort-directive.directive';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NoWhiteSpaceDirectiveDirective } from './directives/no-white-space-directive.directive';

@NgModule({
        declarations: [
                CustomFilterComponent,
                ExportCsvComponent,
                LoaderComponent,
                HeaderComponent,
                FooterComponent,
                OptionFilterComponent,
                PathOptionFilterComponent,
                ProjectInfoComponent,
                FullscreenLoaderComponent,
                NavComponent,
                ProjectCardComponent,
                OverviewCardComponent,
                VulnerabilityComponent,
                StepperComponent,
                StepperItemComponent,
                TimeAgoPipe,
                ToggleButtonComponent,
                NgbdSortableHeader,
                DateRangePickerComponent,
                NoWhiteSpaceDirectiveDirective
        ],
        exports: [
                CustomFilterComponent,
                ExportCsvComponent,
                LoaderComponent,
                HeaderComponent,
                FooterComponent,
                OptionFilterComponent,
                PathOptionFilterComponent,
                ProjectInfoComponent,
                FullscreenLoaderComponent,
                NavComponent,
                ProjectCardComponent,
                OverviewCardComponent,
                VulnerabilityComponent,
                StepperComponent,
                StepperItemComponent,
                TimeAgoPipe,
                ToggleButtonComponent,
                NgbdSortableHeader,
                DateRangePickerComponent,
                NoWhiteSpaceDirectiveDirective
        ],
        imports: [CommonModule, NgbModule, FormsModule, RouterModule, BsDatepickerModule.forRoot()],
})
export class SharedModule { }
