// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';
import { Project } from '../../models/project.model';
import { ProjectService } from '../../services/project.service';
import { AuthService } from '../../services/auth.service';
import * as hexRgb from 'hex-rgb';
@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.scss']
})
export class MyProjectsComponent implements OnInit {
  vdprojects = [];
  pagination = 1;
  loaderType = true;
  isApiLoaded = false;
  hideLoadMoreButton = false;

  constructor(
    private projectService: ProjectService,
    private authService: AuthService) { }

  ngOnInit() {
    this.hideLoadMoreButton = true;
    this.getProjects();
  }

  getProjects() {
    this.loaderType = true;
    this.isApiLoaded = false;
    const lfid = localStorage.getItem('lfId');
    if (this.authService.idToken) {
      this.getProjectsByLFID(lfid);
    } else {
      this.authService.initialAuthCheck.subscribe(response => {
        this.getProjectsByLFID(lfid);
      });
    }
  }

  onScrollDown() {
    this.pagination = this.pagination + 1;
    this.hideLoadMoreButton = true;
    // Load next page and set next page key
    this.getProjects();
  }

  getProjectsByLFID(lfid) {
    this.projectService.getProjectsLfid(this.pagination.toString(), lfid).subscribe((data: any) => {
      if (this.vdprojects.length === 0) {
        this.vdprojects = this.generateCardValues(data);
      } else {
        this.vdprojects = this.vdprojects.concat(this.generateCardValues(data));
      }

      this.isApiLoaded = true;
      this.loaderType = false;
      this.hideLoadMoreButton = (!data || data.length < 12);
    }, (err) => {
      this.loaderType = false;
      this.isApiLoaded = true;
      this.hideLoadMoreButton = true;
    });
  }

  trackProjects(index, project) {
    return project ? project.projectId : undefined;
  }

  private getProjectCardColor(project: Project): string {
    if (project.color.charAt(0) === '#') {
      project.color = project.color.substr(1);
    }
    if (!project.color) {
      return 'rgb(128, 128, 128)';
    }
    const { red, green, blue } = hexRgb(project.color);
    return `rgb(${red}, ${green}, ${blue})`;
  }

  generateCardValues(projectResponse) {
    projectResponse.forEach(obj => {
      const topHeaderStyle = {
        fill: this.getProjectCardColor(obj)
      };
      obj.topHeaderStyle = topHeaderStyle;
      obj.name = obj.name;
    });
    return projectResponse;
  }
}
