import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  options = {
    maxDate: new Date(new Date().setDate(new Date().getDate() - 1))
  };

  today: Date;
  minDate;

  bsConfig = {
    rangeInputFormat: 'MMM DD, YYYY',
    dateInputFormat: 'MMM DD, YYYY',
    showWeekNumbers: false,
    showPreviousMonth: true,
    isAnimated: true
  };

  @Output() dateChanged = new EventEmitter<any>();
  selectedDates = [];
  displayDates = 'Last Scan History';
  resetDatePicker = false;

  selectedType = 'relative';
  relativeData = [
    [
      { text: 'Last Day', value: 'last_day' },
      { text: 'Last 1 Week', value: 'last_1_week' },
      { text: 'Last 1 Month', value: 'last_1_month' }
    ],
    [
      { text: 'Last 3 Months', value: 'last_3_months' },
      { text: 'Last 6 Month', value: 'last_6_months' },
      { text: 'Last 1 Year', value: 'last_1_year' }
    ],
    [
      { text: 'Last 5 Years', value: 'last_5_years' },
      { text: 'All Time', value: 'all_time' },
      { text: 'Last Scan', value: 'last_scan' }
    ],
    [
      { text: 'Week To Date', value: 'week_to_date' },
      { text: 'Month To Day', value: 'month_to_day' },
      { text: 'Quarter To Day', value: 'quarter_to_day' },
      { text: 'Year To Day', value: 'year_to_day' }
    ]
  ];

  selectedRelativeDateRangeType = 'last_scan';

  constructor(
    private modalService: NgbModal
  ) {
    this.today = new Date();
  }

  ngOnInit() {
    // const last6Month = moment().subtract(6, 'months');
    // const lastMonth = moment().subtract(1, 'months');
    // this.selectedDates[0] = last6Month.startOf('month').toString();
    // this.selectedDates[1] = lastMonth.endOf('month').toString();
    const lastYear = moment().subtract(364, 'days');
    this.selectedDates[0] = lastYear.toString();
    this.selectedDates[1] = moment().toString();
    this.save();
  }

  onDateChange(event) {
    let selectedDates = null;
    if (event) {
      selectedDates = {
        startDate: event[0],
        endDate: event[1]
      };
    }

    this.dateChanged.emit({ selectedDates: selectedDates, isReset: this.resetDatePicker });
    this.resetDatePicker = false;
  }

  clearDates() {
    // this.selectedDates = '';
    this.resetDatePicker = true;
  }

  openLg(content, selectedType) {
    this.selectedType = selectedType;
    this.modalService.open(content, { windowClass: 'date-picker-modal' });
  }

  openContent(selectedType) {
    this.selectedType = selectedType;
    this.selectedDates = [];
    this.displayDates = '';
  }

  setDate(item) {
    this.selectedRelativeDateRangeType = item.value;
    const today = moment();
    const lastMonth = today.subtract(1, 'months');

    switch (item.value) {
      case 'last_day':
        const lastDay = moment().subtract(1, 'day');
        this.selectedDates[1] = this.selectedDates[0] = lastDay.toString();
        break;
      case 'last_1_week':
        const lastWeekDate = moment().subtract(7, 'days');
        this.selectedDates[0] = lastWeekDate.startOf('week').toString();
        this.selectedDates[1] = lastWeekDate.endOf('week').toString();
        break;
      case 'last_1_month':
        this.selectedDates[0] = lastMonth.startOf('month').toString();
        this.selectedDates[1] = lastMonth.endOf('month').toString();
        break;
      case 'last_3_months':
        const last3Month = moment().subtract(3, 'months');
        this.selectedDates[0] = last3Month.startOf('month').toString();
        this.selectedDates[1] = lastMonth.endOf('month').toString();
        break;
      case 'last_6_months':
        const last6Month = moment().subtract(6, 'months');
        this.selectedDates[0] = last6Month.startOf('month').toString();
        this.selectedDates[1] = lastMonth.endOf('month').toString();
        break;
      case 'last_1_year':
        const last1Year = moment().subtract(1, 'year');
        this.selectedDates[0] = last1Year.startOf('year').toString();
        this.selectedDates[1] = last1Year.endOf('year').toString();
        break;
      case 'last_5_years':
        const last5Year = moment().subtract(5, 'year');
        this.selectedDates[0] = last5Year.startOf('year').toString();
        this.selectedDates[1] = moment().subtract(1, 'year').endOf('year').toString();
        break;
      case 'week_to_date':
        const last7thDay = moment().subtract(7, 'days');
        this.selectedDates[0] = last7thDay.toString();
        this.selectedDates[1] = moment().toString();
        break;
      case 'month_to_day':
        const last30Days = moment().subtract(30, 'days');
        this.selectedDates[0] = last30Days.toString();
        this.selectedDates[1] = moment().toString();
        break;
      case 'quarter_to_day':
        const lastQuarter = moment().subtract(3, 'months');
        this.selectedDates[0] = lastQuarter.toString();
        this.selectedDates[1] = moment().toString();
        break;
      case 'year_to_day':
        // const lastYear = moment().subtract(1, 'year');
        const lastYear = moment().subtract(364, 'days');
        this.selectedDates[0] = lastYear.toString();
        this.selectedDates[1] = moment().toString();
        break;
      case 'all_time':
        const lastYearForAllTime = moment().subtract(364, 'days');
        this.selectedDates[0] = lastYearForAllTime.toString();
        this.selectedDates[1] = moment().toString();
        this.displayDates = 'Complete Scan History';
        break;
      case 'last_scan':
        const lastYearForLastScan = moment().subtract(364, 'days');
        this.selectedDates[0] = lastYearForLastScan.toString();
        this.selectedDates[1] = moment().toString();
        this.displayDates = 'Last Scan History';
        break;

      default:
        break;
    }

    this.showFormattedDate(item.value);

  }

  showFormattedDate(input) {
    if (input === 'all_time' || input === 'last_scan') {
      // this.selectedDates = [];
      // this.displayDates = 'Complete Scan History';
      return;
    }
    // else if (input === 'last_scan') {
    //   // this.selectedDates = [];
    //   // this.displayDates = 'Last Scan History';
    //   return;
    // }

    const startDate = moment(this.selectedDates[0]).format('MMM DD YYYY');
    let endDate = moment(this.selectedDates[1]).format('MMM DD YYYY');

    endDate = moment().date() === moment(this.selectedDates[1]).date() ? 'Today' : endDate;
    this.displayDates = `${startDate} - ${endDate}`;
  }

  showCalenderFormattedDate(start, end) {
    start = new Date(start);
    this.minDate = start;

    if (start && end) {
      end = new Date(end);

      let startDate = moment(start).format('MMM DD YYYY');
      let endDate = moment(end).format('MMM DD YYYY');

      startDate = moment().date() === moment(start).date() ? 'Today' : startDate;
      endDate = moment().date() === moment(end).date() ? 'Today' : endDate;

      if (startDate === 'Today' && endDate === 'Today') {
        this.displayDates = `${startDate}`;
      } else {
        this.displayDates = `${startDate} - ${endDate}`;
      }
    }
  }

  resetDates() {
    this.selectedDates = [];
    this.displayDates = 'Last Scan History';
    this.selectedRelativeDateRangeType = 'last_scan';
    // this.dateChanged.emit({ selectedDates: [], isReset: true });
  }

  save() {
    this.selectedDates = this.selectedDates.map((d, i) => {
      if (i === 0) {
        d = moment(d).set({ hour: 0, minute: 0, second: 0 }).format();
      } else {
        d = moment(d).set({ hour: 23, minute: 59, second: 59 }).format();
      }

      return d;
    });
    this.dateChanged.emit({ selectedDates: this.selectedDates, isReset: false });
  }
}
