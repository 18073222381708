// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
export const environment = {
    production: true,

    // Added for FS
    fundspringHostname: 'funding.staging.platform.linuxfoundation.org',
    fundspringProjectPath: 'projects',

    // Added for VD
    vdAPIEndpointHostname: 'api.security.staging.platform.linuxfoundation.org',
    vdAPIEndpointProjectPath: 'project',
    vdAPIEndpointDependenciesPath: 'vulnerability/dependencies',
    vdAPIEndpointDependencyGraphPath: 'vulnerability/dependencies/tree',
    vdAPIEndpointLicensesPath: 'vulnerability/licenses',
    vdAPIEndpointIssuesPath: 'vulnerability/issues',
    vdAPIEndpointSummaryPath: 'vulnerability/summary',
    vdAPIEndpointTaskPath: 'task',

    // added for static analysis
    vdAPIEndpointStaticIssuesPath: 'staticanalysis/issues',
    vdAPIEndpointStaticSummaryPath: 'staticanalysis/summary',

    acceptableUsePolicy: 'https://communitybridge.staging.platform.linuxfoundation.org/acceptable-use',
    serviceSpecificTerms: 'https://communitybridge.staging.platform.linuxfoundation.org/service-terms',
    platformUseAgreement: 'https://communitybridge.staging.platform.linuxfoundation.org/platform-use-agreement',
    privacyPolicy: 'https://www.linuxfoundation.org/privacy/',
    trademarkUsage: 'https://www.linuxfoundation.org/trademark-usage/',
    CommunityFunding: 'https://funding.staging.platform.linuxfoundation.org',

    FUNDING_API_URL: 'https://api.funding.staging.platform.linuxfoundation.org',
    PEOPLE_API_URL: 'https://api.people.staging.platform.linuxfoundation.org',
    AUTH_REDIRECT_URL: 'https://security.staging.platform.linuxfoundation.org/#/',
    PEOPLE_URL: 'https://people.staging.platform.linuxfoundation.org',
    REQUEST_ACCESS_LINK_TO_JOIN_PROJECT: 'https://jira.linuxfoundation.org/servicedesk/customer/portal/4/create/230',
};
