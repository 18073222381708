// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

export class CveIdentifier {
    name: string;
    url: string;

    constructor(name: string) {
        this.name = name;
        this.url = this.getUrl();
    }

    private getUrl() {
        return `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${this.name}`;
    }
}

export class CweIdentifier {
    name: string;
    url: string;

    constructor(name: string) {
        this.name = name;
        this.url = this.getUrl();
    }

    private getUrl() {
        if (!this.name) {
            return;
        }
        return `https://cwe.mitre.org/data/definitions/${this.name.split('-')[1]}.html`;
    }
}

// dependency issues
export class Issue {
    cveIdentifiers: CveIdentifier[];
    cweIdentifiers: CweIdentifier[];
    details: string;
    isFixable: boolean;
    overview: string;
    references: string;
    remediation: string;
    snykId: string;
    status: string;
    severity: string;
    title: string;

    constructor(json: any) {
        this.details = json.details;
        this.isFixable = json.isFixable;
        this.overview = json.overview;
        this.references = json.references;
        this.remediation = json.remediation;
        this.snykId = json.snykId;
        this.status = json.status;
        this.severity = json.severity;
        this.title = json.title;
        this.cveIdentifiers = this.getCveIdentifiers(json.cveIdentifiers || []);
        this.cweIdentifiers = this.getCweIdentifiers(json.cweIdentifiers || []);
    }

    private getCveIdentifiers(ids: string[]): CveIdentifier[] {
        return ids.map(identifier => new CveIdentifier(identifier));
    }

    private getCweIdentifiers(ids: string[]): CweIdentifier[] {
        return ids.map(identifier => new CweIdentifier(identifier));
    }
}

// static analysis issues
export class ProjectIssue {
    codeSnippet: string;
    codeSnippetURL: string;
    createdAt: number;
    description: string;
    filePath: string;
    overview: string;
    projectId: string;
    repositoryId: string;
    severity: string;
    startLine: number;
    status: string;
    stopLine: number;
    updatedAt: number;
}

export enum IssueType {
    dependency,
    static,
}
