import { Directive, ElementRef, OnChanges, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoWhiteSpace]'
})

export class NoWhiteSpaceDirectiveDirective {

  constructor(private ref: ElementRef, private control: NgControl) {
  }

  @HostListener('input')
  trimText() {
    if (this.ref.nativeElement.value !== null && this.ref.nativeElement.value !== '') {
      const isWhitespace = (this.ref.nativeElement.value || '').trim().length === 0;
      if (isWhitespace) {
        this.ref.nativeElement.value = (this.ref.nativeElement.value || '').trim();
        this.control.control.setValue(this.ref.nativeElement.value);
      }
    }
  }

}
