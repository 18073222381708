// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../globals';
@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
    externalId = '';
    isActiverouter: string;
    isClosed = true;
    routeName = 'overview';
    projectInfo = null;
    openMenu() {
        this.isClosed = !this.isClosed;
    }

    constructor(private router: Router, private route: ActivatedRoute,
        private globals: Globals) {
        router.events.subscribe((val) => {
            this.routeName = this.router.url.split("/")[2] && this.router.url.split("/")[2].split('?')[0];
        });
    }

    ngOnInit() {
        this.projectInfo = this.globals.projectInfo;
        this.isActiverouter = this.router.url.split("/")[2];
        this.externalId = this.route.root.snapshot.firstChild.params.externalId;
    }

    getRoute(name: string) {
        this.routeName = name;
        return `/${this.externalId}/landing/${name}`;
    }
}
