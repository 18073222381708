// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { Project, ProjectResponse, ProjectCard } from '../../models/project.model';
import * as hexRgb from 'hex-rgb';
import { AppConstants } from 'src/config/constants/app-constants';
import { sortByStringField } from 'src/app/shared/utility/helper-function';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent implements OnInit {
  @ViewChild('searchbox') input: ElementRef;
  projectCards = [];
  onPageScroll = false;
  loaderType;
  pagination = 1;
  searchLoader;
  searchValue: string;
  loadProjects = false;
  hideLoadMoreButton = false;
  // tslint:disable-next-line: max-line-length
  alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  selectedAlphabetToFilter = '';
  apiTimeout;
  sortType = 'desc';
  sortBy = 'total_open_issues';

  constructor(
    private projectService: ProjectService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getAllProjectsOnLoad();
  }

  getAllProjectsOnLoad() {
    this.pagination = 1;
    this.loaderType = true;
    this.hideLoadMoreButton = true;
    this.projectCards = [];

    this.getVDProjects().subscribe((projectResponse: any) => {
      projectResponse = this.excludeProjects(projectResponse);
      this.createCards(projectResponse, true);
      this.loaderType = false;
    }, () => {
      this.hideLoadMoreButton = true;
      this.loaderType = false;
    });
  }

  createCards(projects = [], concatProjects = false) {
    // hide laod more button if projects less than 12
    this.hideLoadMoreButton = !projects || projects.length < 12;

    projects = projects.filter(project => Object.keys(project).length)
      .map((p: any) => (this.convertProjectIntoProjectCard(p)));

    if (concatProjects) {
      this.projectCards = [...this.projectCards, ...projects];
    } else {
      this.projectCards = projects;
    }

  }

  onScrollDown() {
    this.onPageScroll = true;
    this.pagination = this.pagination + 1;
    this.getProjectScroll();
    // Load next page and set next page key
  }

  getProjectScroll() {
    this.loadProjects = true;
    this.hideLoadMoreButton = true;

    this.searchValue = this.selectedAlphabetToFilter ? this.selectedAlphabetToFilter : this.searchValue;
    this.getVDProjects(this.pagination.toString(), this.searchValue && this.searchValue.toLowerCase())
      .subscribe((projectResponse: any) => {
        projectResponse = this.excludeProjects(projectResponse);
        this.createCards(projectResponse, true);
        this.loadProjects = false;
      }, () => {
        this.loadProjects = false;
        this.hideLoadMoreButton = true;
      });
  }

  searchProjects(input) {
    this.searchValue = input.currentTarget.value;
    if (this.apiTimeout) {
      clearTimeout(this.apiTimeout);
    }

    this.apiTimeout = setTimeout(() => {
      if (this.searchValue && this.searchValue.length > 2) {
        this.searchLoader = true;
        this.selectedAlphabetToFilter = '';
        this.pagination = 1;
        this.removeAllFromAlphabate();
        this.loaderType = true;
        this.hideLoadMoreButton = true;
        this.projectCards = [];
        this.getVDProjects(this.pagination.toString(), '', this.searchValue.trim()).subscribe((projectResponse: any) => {
          setTimeout(() => {
            projectResponse = this.excludeProjects(projectResponse);
            this.createCards(projectResponse);
            this.searchLoader = false;
            this.loaderType = false;
          }, 700);
        }, () => {
          this.searchLoader = false;
          this.loaderType = false;
        });
      } else if (this.searchValue.length === 0) {
        this.searchLoader = false;
        this.getAllProjectsOnLoad();
      }
    }, 1000);
  }

  goToPage(pageName: string) {
    this.router.navigate([pageName]);
  }

  private getProjectCardColor(project: Project): string {
    if (!project || !project.color) {
      return 'rgb(128, 128, 128)';
    }

    if (project.color.charAt(0) === '#') {
      project.color = project.color.substr(1);
    }
    const { red, green, blue } = hexRgb(project.color);
    return `rgb(${red}, ${green}, ${blue})`;
  }

  private convertProjectIntoProjectCard(project: Project): ProjectCard {
    const rgbString = this.getProjectCardColor(project);

    return {
      projectId: project.projectId,
      topHeaderStyle: {
        fill: rgbString
      },
      logoUrl: project.logoUrl,
      tags: [project.industry],
      name: project.name,
      description: project.description,
      slug: project.slug,
      jobspringProjectId: project.jobspringProjectId,
      contributors: project.contributors,
      isFunding: project.isFunding,
      totalOpenIssues: project.totalOpenIssues
    };
  }

  trackProjects(index, project) {
    return project ? project.projectId : undefined;
  }

  private getVDProjects(nextPageKey?: string, beginwith = '', filter = ''): Observable<ProjectResponse> {
    return this.projectService.getVDProjects(nextPageKey, beginwith, filter, this.sortType, this.sortBy);
  }

  clearSearch() {
    this.searchValue = '';
    this.input.nativeElement.value = '';
    this.getAllProjectsOnLoad();
  }

  excludeProjects(projects) {
    // C/C++ based project excluded
    // We don't scan C/C++ repos and Linux Kernel is C based project.
    return projects && projects
      .filter(x => x.name &&
        !AppConstants.skipProjects.includes(x.name.replace(/ /g, "_").toLowerCase()));
  }

  filterByAlphabetically(alphabet) {
    this.selectedAlphabetToFilter = alphabet;
    this.input.nativeElement.value = '';
    this.searchValue = '';

    if (this.selectedAlphabetToFilter && this.selectedAlphabetToFilter.toLowerCase() === 'all') {
      this.selectedAlphabetToFilter = '';
      this.searchValue = '';
      this.getAllProjectsOnLoad();
      this.removeAllFromAlphabate();
      return;
    }

    this.loaderType = true;
    this.hideLoadMoreButton = true;
    this.pagination = 1;
    this.projectCards = [];
    this.getVDProjects(this.pagination.toString(), this.selectedAlphabetToFilter.toLowerCase())
      .subscribe((projectResponse: any) => {
        setTimeout(() => {
          projectResponse = this.excludeProjects(projectResponse);
          this.createCards(projectResponse);
          this.loaderType = false;

          if (this.alphabets.findIndex(x => x.toLowerCase() === 'all') === -1) {
            this.alphabets.unshift('All');
          }
        }, 700);
      }, () => {
        this.loaderType = false;
      });
  }

  removeAllFromAlphabate() {
    const isAllExistsInList = this.alphabets.findIndex(x => x.toLowerCase() === 'all');
    if (isAllExistsInList > -1) {
      this.alphabets.splice(isAllExistsInList, 1);
    }
  }

  sortByVulnerability() {
    this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    this.loaderType = true;
    this.hideLoadMoreButton = true;
    this.pagination = 1;
    this.projectCards = [];

    this.getVDProjects(this.pagination.toString(), this.selectedAlphabetToFilter.toLowerCase(), this.searchValue && this.searchValue.trim())
      .subscribe((projectResponse: any) => {
        setTimeout(() => {
          projectResponse = this.excludeProjects(projectResponse);
          this.createCards(projectResponse);
          this.loaderType = false;
        }, 700);
      }, () => {
        this.loaderType = false;
      });
  }
}
