// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit, OnDestroy {
  @Input() projectInfo: any;
  dataCountTextWithRepoAndFolderPath = '';
  subscription: Subscription;

  constructor(
    private projectService: ProjectService
  ) {
    if (this.projectService.repositoryDetails) {
      this.subscription = this.projectService.repositoryDetails.subscribe(repo => {
        this.dataCountTextWithRepoAndFolderPath = '<span class="dropdown-repo-details">' +
          this.showCountText(repo, repo.isForIssuesPage, repo.isForDependency)
          + '</span>';
      });
    }
  }

  ngOnInit() {
  }

  showCountText(repo, isForIssuesPage, isForDependency) {
    const folderPath = repo.selectedFolderPath
      && repo.repository.folderPaths.filter(x => x.repositoryFolderpathId === repo.selectedFolderPath);

    if (repo.selectedFolderPath && folderPath) {
      return this.showDetails(repo.repository.text, folderPath[0], true, repo.repository.text, isForIssuesPage, isForDependency);
    } else {
      return this.showDetails(repo.repository.text, repo.repository, false, repo.repository.text, isForIssuesPage, isForDependency);
    }
  }

  showDetails(text, option, isFolderPath, parentRepoText, isForIssuesPage, isForDependency) {
    text = this.setRepoFolderPathName(text, parentRepoText, option);

    if (isForIssuesPage && option) {
      const textToShow = (issuesCountText) => {
        return `${text} <span class="issue-color">${issuesCountText}</span>`;
      };

      if (isFolderPath) {
        option.fixedIssuesCount = option.totalFixedIssueCount;
        option.openIssuesCount = option.totalOpenIssueCount;
      }

      if (!option.fixedIssuesCount && !option.openIssuesCount) {
        return textToShow(`(0 issues)`);
      }

      if (!isFolderPath) {
        return textToShow(`(${option.openIssuesCount} open + ${option.fixedIssuesCount} fixed) issues`);
      }

      return textToShow(`(${option.openIssuesCount} open + ${option.fixedIssuesCount} fixed) issues`);
    } else if (isForDependency && option) {
      return `${text}
         <span class="dependency-count-color">(${option.totalDependencyCount +
        (option.totalDependencyCount === 1 ? ' dependency' : ' dependencies')})</span>`;
    }

    return text;
  }

  setRepoFolderPathName(folderPath, repoName, option) {
    // show root('/') if repo name matches to folder path
    // else prepend / before folder path
    // appended package manager

    if (option.folderPath) {
      folderPath = `${repoName} :: ${repoName === option.folderPath ? '/' : option.folderPath}`;
      folderPath += option.packageManager ? ' :: ' + option.packageManager : '';
    }

    return folderPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
