// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';
import { NgbModalConfig, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from 'src/app/services/project.service';
import { AppConstants } from 'src/config/constants/app-constants';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit, OnChanges {
  @Input() repositoryDetails;
  @Input() projectId;
  isEnabled = false;
  popupBodyMsg = '';
  toolTipMsg = '';

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.repositoryDetails) {
      this.showWarningWhenDisablingRepo();
      if (this.repositoryDetails.enable) {
        this.isEnabled = true;
      }
    }
  }

  enableDisableRepo() {
    this.repositoryDetails.enable = !this.repositoryDetails.enable;
    const updateRepository = {
      enable: this.repositoryDetails.enable
    };

    this.projectService.updateRepositoryStatus(
      this.projectId, this.repositoryDetails.repositoryId, updateRepository).
      subscribe(result => {
        this.showWarningWhenDisablingRepo();
      });
    this.modalService.dismissAll();
  }

  showWarningWhenDisablingRepo() {
    this.toolTipMsg = this.repositoryDetails.enable && this.repositoryDetails.dependencyCount
      ? AppConstants.Warning_While_Disabling_Repository
      : '';
  }
}
