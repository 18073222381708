// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApproveService {

  constructor(private http: HttpClient) { }
  protocol = '//';
  approve_project(jwt: string): Observable<any> {
    const token = { token: jwt };
    const route = this.protocol + environment.vdAPIEndpointHostname + '/vdproject/projects/approvals';
    return this.http.post<any>(route,
      token)
      .pipe(
        catchError(this.handleError)
      );
  }

  viewProject(projectID: string): Observable<any> {
    return this.http.get<any>(`${this.protocol}${environment.vdAPIEndpointHostname}/vdproject/${projectID}`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
