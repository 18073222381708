// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Issue, ProjectIssue } from '../models/issues';
import { Globals } from '../globals';

@Injectable({
    providedIn: 'root',
})
export class IssuesService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getDependencyIssues(externalId: string, repositoryId: string, params: any) {
        return this.http.get<Issue[]>(
            [
                this.globals.getApiHostname(externalId),
                'repository',
                repositoryId,
                this.globals.getApiPath('issues'),
            ].join('/'),
            { params }
        );
    }

    // getProjectIssues(externalId: string, repositoryId: string, params: any) {
    //     return this.http.get<ProjectIssue[]>(
    //         [
    //             this.globals.getApiHostname(externalId),
    //             'repository',
    //             repositoryId,
    //             this.globals.getApiPath('staticanalysis/issues'),
    //         ].join('/'),
    //         { params }
    //     );
    // }

    getDependencyIssuesCSV(externalId: string, repositoryId: string, params: any) {
        const filterParams = new HttpParams({
            fromObject: params,
        });
        window.open(
            [
                this.globals.getApiHostname(externalId),
                'repository',
                repositoryId,
                this.globals.getApiPath('issues'),
            ].join('/') +
            '?format=csv&' +
            filterParams.toString()
        );
    }

    getProjectIssuesCSV(externalId: string, repositoryId: string, params: any) {
        const filterParams = new HttpParams({
            fromObject: params,
        });
        window.open(
            [
                this.globals.getApiHostname(externalId),
                'repository',
                repositoryId,
                this.globals.getApiPath('staticanalysis/issues'),
            ].join('/') +
            '?format=csv&' +
            filterParams.toString()
        );
    }

    // V1

    getProjectIssues(projectId = '', queryParams = {}) {
        let requestQueryParams = '?';
        Object.entries(queryParams).forEach(([key, value]) => {
            requestQueryParams += `${key}=${value}&`;
        });

        return this.http.get<any>(
            [
                this.globals.getApiHostname(projectId),
                this.globals.getApiPath('issues'),
            ].join('/') + requestQueryParams);
    }

    getProjectRepositoryIssues(projectId = '', repositoryId = '', queryParams = {}) {
        let requestQueryParams = '?';
        Object.entries(queryParams).forEach(([key, value]) => {
            requestQueryParams += `${key}=${value}&`;
        });

        return this.http.get<any>(
            [
                this.globals.getApiHostname(projectId),
                'repository',
                repositoryId,
                this.globals.getApiPath('issues'),
            ].join('/') + requestQueryParams);
    }

    getRepositories(projectId) {
        return this.http.get<any>(
            [
                this.globals.getApiHostname(projectId),
                'repositories'].join('/'));
    }
}
