// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-loader',
  templateUrl: './icon-loader.component.html',
  styleUrls: ['./icon-loader.component.scss']
})
export class IconLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
