// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Project, ProjectResponse, ProjectMembers, SummaryInfo } from '../models/project.model';
import { UserType } from 'src/config/enums/user-type.enum';

@Injectable({
  providedIn: 'root'
})

export class ProjectService {
  projectVulnerablities = [];
  repositoryDetails = new Subject<any>();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  protocol = '//';
  limit = 100;
  getProjectInfo(urlBase: string, externalId: string) {
    return this.http.get<Project[]>([
      urlBase,
      externalId
    ].join("/"));
  }

  getVDProjects(nextPageKey: string, beginwith = '', filter = '',
    sortOrder = 'desc', sortBy = 'total_open_issues'): Observable<ProjectResponse> {
    const page = `/vdproject?beginwith=${beginwith}&filter=${filter}&pageno=${nextPageKey ? nextPageKey : 1}&limit=12&sort_order=${sortOrder}&sort_by=${sortBy}`;
    return this.http.get<ProjectResponse>(`${this.protocol}${environment.vdAPIEndpointHostname}${page}`)
      .pipe(catchError(this.handleError));
  }
  getProjectsLfid(nextPageKey: string, lfid: string): Observable<ProjectResponse> {
    const httpOptions = {
      headers: this.authService.getRequestHeaders(),
    };
    const page = `/vdproject/${lfid}/me/projects?pageno=${nextPageKey ? nextPageKey : 1}&limit=12`;
    return this.http.get<ProjectResponse>(`${this.protocol}${environment.vdAPIEndpointHostname}${page}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getProjectDetailsByIDMentor(projectId: string): Observable<Project> {
    return this.http.get<Project>(environment.PEOPLE_API_URL + '/projects/' + projectId + '/mentors').pipe(catchError(this.handleError));
  }

  getProjectDetailsByIDMentee(projectId: string): Observable<Project> {
    return this.http.get<Project>(environment.PEOPLE_API_URL + '/projects/' + projectId + '/active-mentees')
      .pipe(catchError(this.handleError));
  }

  getProject(projectId: string): Observable<SummaryInfo> {
    return this.http.get<SummaryInfo>(this.getApiHostname(projectId) + '/vulnerability/summary')
      .pipe(catchError(this.handleError));
  }

  getProjectMembers(projectId: string): Observable<ProjectMembers[]> {
    return this.http.get<ProjectMembers[]>(environment.PEOPLE_API_URL + '/projects/' + projectId + '/member')
      .pipe(catchError(this.handleError));
  }

  getSummaryReport(projectIds): Observable<any> {
    return this.http.
      get<any>(`${this.protocol}${environment.vdAPIEndpointHostname}/vulnerability/summary?project_ids=${projectIds}`)
      .pipe(catchError(this.handleError));
  }

  getVDProjectDetailsByProjectId(projectId: string): Observable<Project> {
    return this.http.get<Project>(`${this.protocol}${environment.vdAPIEndpointHostname}`
      + '/vdproject/' + projectId).pipe(catchError(this.handleError));
  }

  getProjectRepositories(projectId, searchText = '', sortColumn = '', sortOrder = 'asc', offset = 0, limit = 20) {
    return this.http.
      // tslint:disable-next-line: max-line-length
      get<any>(`${this.protocol}${environment.vdAPIEndpointHostname}/project/${projectId}/repository/?filter=${searchText}&sort_by=${sortColumn}&sort_order=${sortOrder}&limit=${limit}&offset=${offset}`)
      .pipe(catchError(this.handleError));
  }

  updateRepositoryStatus(projectId, repoId, body) {
    return this.http.
      put<any>(`${this.protocol}${environment.vdAPIEndpointHostname}/project/${projectId}/repository/${repoId}/update-status`
        , body)
      .pipe(catchError(this.handleError));
  }

  getProjectRepositoriesScanDetails(projectId, repositoryId = '', excludeDisableRepository = false) {

    return this.http.
      // tslint:disable-next-line: max-line-length
      get<any>(`${this.protocol}${environment.vdAPIEndpointHostname}/vulnerability/error-summary?project_ids=${projectId}&repository_ids=${repositoryId}&exclude_disable_repository=${excludeDisableRepository}`)
      .pipe(catchError(this.handleError));
  }

  // v1
  getV1ProjectStatistics(projectId, queryParams) {
    let requestQueryParams = '?';
    Object.entries(queryParams).forEach(([key, value]) => {
      requestQueryParams += `${key}=${value}&`;
    });

    return this.http.
      // tslint:disable-next-line: max-line-length
      get<any>(`${this.protocol}${environment.vdAPIEndpointHostname}/v1/project/${projectId}/vulnerability/statistics` + requestQueryParams)
      .pipe(catchError(this.handleError));
  }

  getV1ProjectVersionTree(projectId, queryParams) {
    let requestQueryParams = '?';
    Object.entries(queryParams).forEach(([key, value]) => {
      requestQueryParams += `${key}=${value}&`;
    });

    return this.http.
      // tslint:disable-next-line: max-line-length
      get<any>(`${this.protocol}${environment.vdAPIEndpointHostname}/v1/project/${projectId}/release/overview` + requestQueryParams)
      .pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private getApiHostname(externalId: string) {
    return this.protocol + [environment.vdAPIEndpointHostname, environment.vdAPIEndpointProjectPath, externalId].join('/');
  }

  isMaintainerOrContributor(projectDetails) {
    // allow maintainer and contributor to access overview page.
    // here for contributor we need to check email with userType
    // as we don't have contributor  userId

    const userTypesToAllow = [UserType.maintainer, UserType.contributor];
    const loggedUserEmail = localStorage.getItem('email') ? localStorage.getItem('email').toLowerCase() : '';
    const loggedUserId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '';

    // if project from VD then check contributors
    let vdProjContributors: any[] = projectDetails.contributors;
    vdProjContributors = vdProjContributors && vdProjContributors.filter(x => userTypesToAllow.includes(x.userType));

    if (vdProjContributors && vdProjContributors.length) {
      const contributor = vdProjContributors
        .find(x => x.userId === loggedUserId || (x.email && x.email.toLowerCase() === loggedUserEmail));

      if (contributor) {
        return contributor.userId === loggedUserId || contributor.email === loggedUserEmail;
      }
    }

    return false;
  }

  showRepositoryDetailsAfterProjectName(selectedRepository = null, selectedFolderPath = null,
    isForIssuesPage = false, isForDependency = false) {
    this.repositoryDetails.next({
      showRepositoryDetails: true,
      repository: selectedRepository,
      selectedFolderPath: selectedFolderPath,
      isForIssuesPage: isForIssuesPage,
      isForDependency: isForDependency
    });
  }
}
