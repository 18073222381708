// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Dependency } from '../models/dependency';
import { DependencyTree } from '../models/dependencyTree';
import { Globals } from '../globals';
import { sortByStringField } from '../shared/utility/helper-function';

@Injectable({
    providedIn: 'root',
})
export class DependenciesService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getDependencies(externalId = '', repositoryId = '', pathId = '', dependenciesFilters: object) {
        return this.http.get<Dependency[]>(
            [
                this.globals.getApiHostname(externalId),
                'repository',
                repositoryId,
                this.globals.getApiPath('dependencies'),
            ].join('/') + '?pathID=' + pathId,
            {
                params: new HttpParams({
                    fromObject: this.globals.getParamsFromFilters(dependenciesFilters),
                }),
            }
        );
    }

    getDependenciesCSV(externalId: string, repositoryId: string, repositoryFolderPathId = '') {
        window.open(
            [
                this.globals.getApiHostname(externalId),
                'repository',
                repositoryId,
                this.globals.getApiPath('dependencies'),
            ].join('/') + '?format=csv&repositoryFolderPathId=' + repositoryFolderPathId
        );
    }

    getDependencyGraph(externalId = '', repositoryId = '', pathId = '') {
        return this.http.get<DependencyTree>(
            [
                this.globals.getApiHostname(externalId),
                'repository',
                repositoryId,
                this.globals.getApiPath('dependencyGraph'),
            ].join('/') + '?pathID=' + pathId,
        );
    }

    sortReposByIssuesCount(repositories: any[]) {
        if (!repositories || !repositories.length) {
            return repositories;
        }

        // sort parent repo
        // sort by open issue count and
        // then total issue count
        repositories = sortByStringField(repositories, 'totalIssueCount', 'desc');
        repositories = sortByStringField(repositories, 'openIssuesCount', 'desc');

        // sort sub folder paths
        repositories = this.sortReposFolderPath(repositories, 'totalIssueCount');

        return repositories;
    }

    sortReposByDependencyCount(repositories: any[]) {
        if (!repositories || !repositories.length) {
            return repositories;
        }

        // sort parent repo
        repositories = sortByStringField(repositories, 'totalDependencyCount', 'desc');

        // sort sub folder paths
        repositories = this.sortReposFolderPath(repositories, 'totalDependencyCount');

        return repositories;

    }

    sortReposFolderPath(repositories: any[], propToSort) {
        if (!repositories || !repositories.length || !propToSort) {
            return repositories;
        }

        repositories.forEach(ele => {
            const folderPaths = [];
            ele.folderPaths.forEach(e => {
                if (e.folderPath === ele.name) {
                    // if folder path and repo name is same
                    // then moved it at first position
                    folderPaths.unshift(e);
                } else {
                    folderPaths.push(e);
                }
            });
            ele.folderPaths = sortByStringField(folderPaths, propToSort, 'desc');
        });

        return repositories;
    }

    // v1

    getV1DependencyGraph(externalId = '', queryParams = {}) {
        let requestQueryParams = '?';
        Object.entries(queryParams).forEach(([key, value]) => {
            requestQueryParams += `${key}=${value}&`;
        });

        return this.http.get<DependencyTree>(
            [
                this.globals.getApiHostname(externalId),
                'dependencies',
            ].join('/') + requestQueryParams,
        );
    }
}
